import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  InputGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Icons } from "../../Assets/icons";
import { Images } from "../../Assets/images";
import { CommonAPI } from "../../Apis/commonApi";
import { useToastWrapper } from "../../Wrapper/toastWrapper";
import { set } from "immer/dist/internal";
import { DatePicker, Modal, TimePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { disabledDate } from "../../Utils/Validator";
import { MdClose } from "react-icons/md";
import ConfirmationModal from "./ConfirmationModal";

type Props = {
  handleCloseModal: () => void;
  responderId?: string;
  company?: string;
  visitorCompanyName?: string;
  designation?: string;
  eventId: string;
  meetRequesterPhone?: string;
};

const CreateMeetModal = (props: Props) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState<any>("");

  const [meetConfirm, setMeetConfirm] = useState(false);
  const handleOpenMeetConfirmModal = () => setMeetConfirm(true);
  const handleCloseMeetConfirmModal = () => {
    setMeetConfirm(false);
  };

  const { success, error } = useToastWrapper();

  const clearData = () => {
    setDate("");
    setTime("");
  };

  const [meetTimeConfirm, setMeetTimeConfirm] = useState(false);
  const handleOpenMeetTimeConfirmModal = () => setMeetTimeConfirm(true);
  const handleCloseMeetTimeConfirmModal = () => setMeetTimeConfirm(false);

  const handleConfirmCancelModal = async () => {
    if (!date || !time) return error("Please fill all the fields");
    // if (!props.meetRequesterPhone)
    //   return error("Please provide your contact number in the profile page");
    const data = {
      proposedDate: date,
      proposedTime: time,
      eventId: props.eventId,
    };

    try {
      const res = await CommonAPI.PostCreateMeeting(props.responderId, data);
      console.log(res);
      success("Meeting request sent");
      clearData();
      props.handleCloseModal();
      handleCloseMeetConfirmModal();
      handleCloseMeetTimeConfirmModal();
    } catch (err) {
      console.log(err);
    }
  };

  const checkMeetStatus = async () => {
    try {
      console.log("inside button accept click2 checkMeetStatus()");

      const response = await CommonAPI.CheckMeetingAcceptAllowed("NEWREQUEST");
      if (response) {
        // console.log("response..", response.data.data);
        const flag = response.data.data.isAllowed;
        if (flag) {
          console.log(
            "inside button accept click2 checkMeetStatus() if condition"
          );

          // allowed to accept meet
          // buttonTypeAcceptClick(item, item._id);
          handleSubmit();
        } else {
          console.log(
            "inside button accept click2 checkMeetStatus() else condition"
          );

          // show confirmation and continue on yes
          handleOpenMeetTimeConfirmModal();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    if (!date || !time) return error("Please fill all the fields");
    // if (!props.meetRequesterPhone)
    //   return error("Please provide your contact number in the profile page");
    const data = {
      proposedDate: date,
      proposedTime: time,
      eventId: props.eventId,
    };

    console.log("date...", new Date(date).getDay());
    console.log("time...", parseInt(time.split(":")[0]));

    try {
      const propDate = new Date(date);
      const propTime = parseInt(time.split(":")[0]);

      if (propDate.getDay() !== 0 && propTime >= 9 && propTime <= 19) {
        handleConfirmCancelModal();
      } else {
        handleOpenMeetConfirmModal();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Box h={"100%"} w={"100%"}>
      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={meetTimeConfirm}
        onOk={handleCloseMeetTimeConfirmModal}
        onCancel={handleCloseMeetTimeConfirmModal}
      >
        <ConfirmationModal
          handleCloseModal={() => {
            handleCloseMeetConfirmModal();
            handleCloseMeetTimeConfirmModal();
          }}
          handleSubmit={() => {
            handleSubmit();
          }}
          title={"Another meeting exists"}
          message={
            "There is a confirmed meeting within 30 minutes of this meeting. Continue?"
          }
          confirmBtnText={"Yes, Confirm"}
          rejectButtonText={"No"}
        />
      </Modal>

      <Modal
        closeIcon={null}
        footer={null}
        title={null}
        open={meetConfirm}
        onOk={handleCloseMeetConfirmModal}
        onCancel={handleCloseMeetConfirmModal}
      >
        <ConfirmationModal
          handleCloseModal={handleCloseMeetConfirmModal}
          handleSubmit={() => {
            handleConfirmCancelModal();
          }}
          title={"Please confirm action"}
          message={
            "Meeting time is set outside of regular office hours.  Continue ?"
          }
          confirmBtnText={"Yes, Create"}
          rejectButtonText={"No"}
        />
      </Modal>
      <VStack
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        className={"create-meeting-modal"}
      >
        {/* <Text
          fontSize={"20px"}
          fontWeight={700}
          textAlign={"center"}
          color={"black"}
        >
           Create meeting 
        </Text> */}
        {/* <Image
          onClick={() => {
            props.handleCloseModal();
          }}
          position={"absolute"}
          src={Icons.cross}
          right={0}
          top={0}
        /> */}
        <Box position={"absolute"} right={0} top={0} py={3} cursor={"pointer"}>
          <MdClose
            size={20}
            onClick={() => {
              props.handleCloseModal();
            }}
          />
        </Box>
      </VStack>

      <Flex direction={"column"} justifyContent={"center"}>
        {/* <Flex justifyContent={'center'} >
            <Image src={Images.schedule} alt="schedule" width={'80px'}/>
        </Flex> */}
        <Text
          fontSize={"md"}
          fontWeight={"regular"}
          mb={"16px"}
          textAlign={"center"}
        >
          Meeting request
        </Text>
        <Text
          fontSize={"sm"}
          mb={"11px"}
          fontWeight={"medium"}
          textAlign={"center"}
        >
          {props.company}
        </Text>
        <Text
          fontSize={"sm"}
          mb={"7px"}
          fontWeight={"medium"}
          textAlign={"center"}
        >
          {props.visitorCompanyName}
        </Text>
        <Text fontSize={"sm"} fontWeight={"light"} textAlign={"center"}>
          {props.designation}
        </Text>
        <FormControl mt={4}>
          {/* <FormLabel>Date</FormLabel> */}
          <Text fontSize={"sm"} fontWeight={"light"} fontStyle={"italic"}>
            *Fix appointments for telephonic meetings
          </Text>
          <InputGroup>
            <DatePicker
              value={date ? dayjs(date) : null}
              disabledDate={disabledDate}
              onChange={(time, dateString) => {
                console.log(time, dateString);
                setDate(dateString);
              }}
              style={{ width: "100%", height: "50px" }}
              placeholder="Choose Date"
              inputReadOnly
              superNextIcon={null}
              superPrevIcon={null}
              popupClassName="custom-date-picker-meeting-popup"
            />
          </InputGroup>
        </FormControl>
        <FormControl mt={5}>
          {/* <FormLabel>Time</FormLabel> */}
          <input
            type="time"
            placeholder="Choose Time"
            style={{
              width: "100%",
              height: "50px",
              padding: "0 10px",
              border: "1px solid #E2E8F0",
              background: "white",
            }}
            onChange={(e) => {
              setTime(e.target.value);
            }}
            value={time}
          />
          {/* <InputGroup>
            <TimePicker
              value={time ? dayjs(time) : null}
              onChange={(date, timeString) => {
                console.log(date, timeString);
                setTime(timeString);
              }}
              onSelect={(time) => {
                console.log("sel", dayjs(time).format("h:mm a"));
                setTime(time);
              }}
              style={{ width: "100%", height: "50px" }}
              placeholder="Choose TIme"
              format="h:mm a"
              inputReadOnly
            />
          </InputGroup> */}
        </FormControl>
        {/* <Box>
          <Text fontSize={'16px'} mb={1}>
            Date
          </Text>
          <Box>
          <DatePicker
          style={{width:'100%', height:'50px'}} 
          value={dayjs(date)||null}
          onChange={(date, dateString) => {
            setDate(dateString)
          }
          }
          />
          </Box>
        </Box> */}
        <Flex alignItems={"center"} gap={3} mt={"25px"}>
          <Button
            onClick={() => {
              clearData();
              props.handleCloseModal();
            }}
            flex={1}
            width={"50%"}
            p={0}
            variant={"outline"}
            borderColor={"card.borderLight"}
            _hover={{ background: "secondary.light" }}
            borderRadius={"2px"}
          >
            <Text fontSize={"sm"} fontWeight={"regular"}>
              Cancel
            </Text>
          </Button>
          <Button
            width={"50%"}
            flex={1}
            onClick={checkMeetStatus}
            colorScheme="transparent"
            variant={"outline"}
            borderColor={"secondary.main"}
            _hover={{ background: "secondary.light" }}
            borderRadius={"2px"}
          >
            <Text fontSize={"sm"} fontWeight={"regular"}>
              Send
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CreateMeetModal;
